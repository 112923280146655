import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

<script src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"></script>;
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
  integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
/>;

ReactDOM.render(<App />, document.getElementById("root"));

// Implementation of OAuth from https://github.com/auth0-samples/auth0-react-samples

import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

type Props = {
  currentStatus?: string;
  onSelect: (newStatus: string) => any;
};

const colorMapping: { [key: string]: string } = {
  Todo: "primary",
  "In Progress": "success",
  Done: "secondary",
};

const Example = (props: Props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle size="sm" caret color={colorMapping[`${props.currentStatus}`]}>
        {props.currentStatus}
      </DropdownToggle>
      <DropdownMenu right size="sm">
      
        <DropdownItem header >Change Status</DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          style={
            props.currentStatus === "Todo" ? { backgroundColor: "#DFFFDF" } : {}
          }
          onClick={() => {
            props.onSelect("Todo");
          }}
        >
          Todo
        </DropdownItem>
        <DropdownItem
          style={
            props.currentStatus === "In Progress"
              ? { backgroundColor: "#DFFFDF" }
              : {}
          }
          onClick={() => {
            props.onSelect("In Progress");
          }}
        >
          In Progress
        </DropdownItem>
        <DropdownItem
          style={
            props.currentStatus === "Done" ? { backgroundColor: "#DFFFDF" } : {}
          }
          onClick={() => {
            props.onSelect("Done");
          }}
        >
          Done
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default Example;

import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { BlogType } from "../types";
import { useParams } from "react-router";
import * as config from "../config.json";

const getBlog = async (id: string) => {
  try {
    const response = await axios.get(`${config.apiDomain}/public/blogs/${id}`);
    return response?.data;
  } catch (error) {
    return {};
  }
};

interface Params {
  blogId: string;
}

export function Blog() {
  const blogId = useParams<Params>()?.blogId;
  const [blog, setBlog] = useState<BlogType | undefined>();

  useEffect(() => {
    const func = async () => {
      const response = (await getBlog(blogId)) || {};
      setBlog(response);
    };
    func();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {blog ? (
        <div style={{ alignContent: "center", alignItems: "center" }}>
          <h1
            style={{
              textAlign: "center",
              width: "60%",
              margin: "auto",
              fontSize: "20",
              paddingBottom: "30px",
            }}
          >
            {blog?.title}
          </h1>
          <div
            className="blogContent"
            style={{
              fontFamily: "sans-serif",
              fontSize: "16px",
              textAlign: "left",
            }}
          >
            {" "}
            {ReactHtmlParser(blog?.html || "")}{" "}
          </div>{" "}
        </div>
      ) : null}
    </div>
  );
}

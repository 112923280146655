import React from "react";

const Footer = () => (
  <footer
    className="bg-light p-3 text-center"
    style={{
      position: "fixed",
      bottom: 0,
      width: "100%",
      height: '50px'
    }}
  >
    <p style={{ textAlign: 'center' }}>Forever Blogging</p>
  </footer>
);

export default Footer;

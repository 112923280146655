import React, { useState } from "react";
import {
  Container,
  Button,
  Table,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  CardTitle,
  Card,
  Toast,
  ToastBody,
  Fade,
} from "reactstrap";
import * as config from "../config.json";
import { useEffect } from "react";
import { TodoType } from "../types";
import axios from "axios";
import moment from "moment";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DropdownButton from "../Components/DropdownButton";
import Confirm from "../Components/Confirm";
import EditableText from "../Components/EditableText";

export const TodoList = () => {
  const [todos, setTodos] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentTodoId, setCurrentTodoId] = useState("");
  const [title, setTitle] = useState("");
  const [htmlBody, setHtmlBody] = useState("");
  const [newDue, setNewDue] = useState("");

  const getAllTodos = async () => {
    try {
      const res = await Auth.currentSession();
      const token = res.getIdToken().getJwtToken();

      const response = await axios.get(`${config.apiDomain}/private/todos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response?.data;

      setTodos(responseData?.items);
    } catch (error: any) {
      console.error(error);
    }
    setIsSaving(false);
  };

  const deleteTodo = async (todoId: string) => {
    setIsSaving(true);

    const res = await Auth.currentSession();
    const token = res.getIdToken().getJwtToken();

    await axios.delete(`${config.apiDomain}/private/todo/${todoId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    getAllTodos();
  };

  const saveTodo = async (saveTodo: Partial<TodoType>) => {
    setIsSaving(true);

    const res = await Auth.currentSession();
    const token = res.getIdToken().getJwtToken();

    const body: Partial<TodoType> = {};
    if (typeof saveTodo.todoId != undefined) body.todoId = saveTodo.todoId;
    if (typeof saveTodo.html != undefined) body.html = saveTodo.html;
    if (typeof saveTodo.title != undefined) body.title = saveTodo.title;
    if (typeof saveTodo.due != undefined) body.due = saveTodo.due;
    if (typeof saveTodo.status != undefined) body.status = saveTodo.status;

    if (body.todoId) {
      await axios.post(`${config.apiDomain}/private/todo`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      await axios.post(`${config.apiDomain}/private/todo`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    getAllTodos();
    setIsSaving(false);
  };

  const resetTodoForm = () => {
    setCurrentTodoId("");
    setTitle("");
    setHtmlBody("");
  };

  const [todoModal, setTodoModal] = useState(false);
  const todoModalToggle = () => setTodoModal(!todoModal);

  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, []);

  const initialLoad = async () => {
    setIsLoading(true);
    await getAllTodos();
    setIsLoading(false);
  };

  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {/* @todo break this out into another component */}
      <Modal isOpen={todoModal} toggle={todoModalToggle}>
        <ModalHeader toggle={todoModalToggle}>New Todo</ModalHeader>
        <ModalBody>
          <>
            <Label>Title</Label>
            <Input
              value={title}
              onChange={(e: any) => {
                setTitle(e?.target?.value || "");
              }}
              disabled={isSaving}
            />
            <Label>Description</Label>
            <Input
              value={htmlBody}
              type="textarea"
              onChange={(e: any) => {
                setHtmlBody(e?.target?.value || "");
              }}
              style={{
                width: "100%",
                marginBottom: "20px",
              }}
              disabled={isSaving}
            />
            <Label>Due</Label>
            <Input
              style={{ fontSize: "small", width: "150px" }}
              type="date"
              name="date"
              value={moment(newDue || moment()).format("yyyy-MM-DD")}
              onChange={(e) => {
                const newDate = moment(e.target.value).toISOString();
                setNewDue(newDate);
              }}
              id="exampleDate"
              placeholder="date placeholder"
            />
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              saveTodo({
                todoId: currentTodoId ? currentTodoId : undefined,
                title: title,
                due: newDue,
                html: htmlBody,
              });
              todoModalToggle();
            }}
          >
            {currentTodoId ? "Save" : "Create"}
          </Button>
          <Button color="secondary" onClick={todoModalToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {todos && !isLoading && (
        <div style={{ padding: "5px", float: "right" }}>
          <Fade in={todos && !isLoading}>
            <Button
              color="primary"
              onClick={() => {
                todoModalToggle();
                resetTodoForm();
              }}
            >
              Create
            </Button>
          </Fade>
        </div>
      )}

      <h1>Todo List</h1>

      {width > 1200 && todos ? (
        <Table striped>
          <thead>
            <tr>
              <th>Title</th>
              <th>Status</th>
              <th>Due</th>
              <th>Created</th>
              <th>Description</th>
              <th style={{ textAlign: "right" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {todos &&
              todos
                ?.sort((a: TodoType, b: TodoType) => {
                  return moment(a.due).unix() - moment(b?.due).unix();
                })
                ?.map((todo: TodoType) => {
                  return (
                    <tr
                      key={todo?.todoId}
                      style={{
                        padding: "10px",
                        margin: 0,
                        border: "1px #f0f0f0 solid",
                      }}
                    >
                      <td>{todo?.title}</td>
                      <td
                        style={{
                          textAlign: "left",
                          fontSize: "small",
                          width: "160px",
                        }}
                      >
                        <DropdownButton
                          currentStatus={todo.status}
                          onSelect={(newStatus: string) => {
                            saveTodo({
                              todoId: todo.todoId,
                              status: newStatus,
                            });
                          }}
                        ></DropdownButton>
                      </td>
                      <td style={{ fontSize: "small", width: "160px" }}>
                        <Input
                          style={{ fontSize: "small", width: "150px" }}
                          type="date"
                          name="date"
                          value={moment(todo.due).format("yyyy-MM-DD")}
                          onChange={(e) => {
                            const newDate = moment(
                              e.target.value
                            ).toISOString();
                            saveTodo({ todoId: todo.todoId, due: newDate });
                          }}
                          id="exampleDate"
                          placeholder="date placeholder"
                        />
                        {moment(todo.due || "").fromNow()}
                      </td>
                      <td style={{ fontSize: "small" }}>
                        {moment(todo.createdAt || "").fromNow()}
                      </td>
                      <td style={{ fontSize: "small" }}>{todo.html}</td>
                      <td style={{ textAlign: "right" }}>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => {
                            deleteTodo(todo.todoId);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      ) : (
        <Container>
          {todos
            ?.sort((a: TodoType, b: TodoType) => {
              return moment(a.due).unix() - moment(b?.due).unix();
            })
            ?.map((todo: TodoType) => {
              return (
                <div key={todo.todoId}>
                  <Card style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <CardTitle tag="h5" style={{ padding: 5, margin: 5 }}>
                      <div
                        style={{
                          display: "inline-block",
                        }}
                        onClick={() => {
                          setCurrentTodoId(todo.todoId);
                          setNewDue(`${todo.due}`);
                          setTitle(todo.title);
                          setHtmlBody(todo.html);
                          todoModalToggle();
                        }}
                      >
                        {todo.title}
                      </div>
                      <Confirm
                        title="Delete?"
                        message="Are you sure you want to delete?"
                        acceptMessage="Yes"
                        declineMessage="No"
                        onAccept={() => {
                          deleteTodo(todo.todoId);
                        }}
                      ></Confirm>
                    </CardTitle>
                    <CardBody>
                      <EditableText
                        text={todo.html}
                        onSave={(text: string) => {
                          saveTodo({ todoId: todo.todoId, html: text });
                        }}
                      />
                    </CardBody>
                    <div style={{ padding: "5px", backgroundColor: "white" }}>
                      <Input
                        style={{
                          fontSize: "small",
                          width: "150px",
                          display: "inline-block",
                          marginTop: "5px",
                          marginBottom: "5px",
                          float: "left",
                        }}
                        type="date"
                        name="date"
                        value={moment(todo.due).format("yyyy-MM-DD")}
                        onChange={(e) => {
                          const newDate = moment(e.target.value).toISOString();
                          saveTodo({ todoId: todo.todoId, due: newDate });
                        }}
                        id="exampleDate"
                        placeholder="date placeholder"
                      />
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          float: "right",
                        }}
                      >
                        <DropdownButton
                          currentStatus={todo.status}
                          onSelect={(newStatus: string) => {
                            saveTodo({
                              todoId: todo.todoId,
                              status: newStatus,
                            });
                          }}
                        ></DropdownButton>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
        </Container>
      )}
      {!todos && !isLoading && (
        <div className="p-4 my-2 rounded">
          <Toast style={{ alignContent: "center", alignSelf: "center" }}>
            <ToastBody
              onClick={() => {
                todoModalToggle();
                resetTodoForm();
              }}
              style={{ textAlign: "center" }}
            >
              Get started! <br />
              <b>Create a todo </b>
            </ToastBody>
          </Toast>
        </div>
      )}
    </Container>
  );
};

export default TodoList;

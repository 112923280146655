import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

type Props = {
  title: string;
  message: string;
  acceptMessage: string;
  declineMessage: string;
  onAccept: () => any;
};

const ModalExample = (props: Props) => {
  const { title, message, acceptMessage, declineMessage, onAccept } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <Button
        close
        color="danger"
        onClick={toggle}
        style={{ float: "right" }}
        size="sm"
      ></Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggle();
              onAccept();
            }}
          >
            {acceptMessage}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            {declineMessage}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalExample;

import React from "react";
import { useHistory } from "react-router";
import { Button, Col, Container, Jumbotron, Row } from "reactstrap";

interface Props {
  isAuthenticated?: boolean;
}

const Home = (props: Props) => {
  const history = useHistory();
  return (
    <div>
      <hr />
      <div>
        <Jumbotron fluid>
          <Container fluid>
            <h1 className="display-3">Welcome</h1>
            <p className="lead">Get ready to be amazed by a to do list!</p>
          </Container>
        </Jumbotron>
      </div>

      <div className="next-steps my-5">
        <h2 className="my-5 text-center">What can I do next?</h2>
        <Row className="d-flex justify-content-between">
          <Col xs="3"></Col>
          <Col xs="auto" md={5} className="mb-4">
            <h6 className="mb-3" style={{ textAlign: "center" }}>
              {!props?.isAuthenticated && (
                <Button
                  outline
                  size="lg"
                  color="primary"
                  onClick={() => {
                    history.push("/auth/signup");
                  }}
                >
                  Sign Up
                </Button>
              )}
            </h6>
          </Col>
          <Col xs="3"></Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;

import React from "react";
import {
  AmplifySignOut,
  AmplifySignUp,
  AmplifyForgotPassword,
  AmplifyAuthenticator,
} from "@aws-amplify/ui-react";

enum AuthState {
  SignUp = "signup",
  SignOut = "signout",
  SignIn = "signin",
  Loading = "loading",
  SignedOut = "signedout",
  SignedIn = "signedin",
  SigningUp = "signingup",
  ConfirmSignUp = "confirmSignUp",
  confirmingSignUpCustomFlow = "confirmsignupcustomflow",
  ConfirmSignIn = "confirmSignIn",
  confirmingSignInCustomFlow = "confirmingsignincustomflow",
  VerifyingAttributes = "verifyingattributes",
  ForgotPassword = "forgotpassword",
  ResetPassword = "resettingpassword",
  SettingMFA = "settingMFA",
  TOTPSetup = "TOTPSetup",
  CustomConfirmSignIn = "customConfirmSignIn",
  VerifyContact = "verifyContact",
}

const AuthSignUp = () => {
  return (
    <div style={{ padding: "20px" }}>
      <AmplifyAuthenticator
        initialAuthState={AuthState.SignUp}
        usernameAlias="email"
      >
        <AmplifySignUp
          slot="sign-up"
          formFields={[
            {
              type: "username",
              label: "Email *",
              placeholder: "Enter your email",
              inputProps: { required: true, autocomplete: "username" },
            },
            {
              type: "password",
              label: "Password *",
              placeholder: "Password",
              inputProps: { required: true, autocomplete: "new-password" },
            },
          ]}
        />
        <AmplifyForgotPassword
          formFields={[
            {
              type: "username",
              label: "Email *",
              placeholder: "Enter your email",
            },
          ]}
        />
        <AmplifySignOut hidden={true} />
      </AmplifyAuthenticator>
    </div>
  );
};

export default AuthSignUp;

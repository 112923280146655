import moment from "moment";
import React from "react";
import { Card, CardText, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { BlogType } from "../types";

interface Props {
  blog: BlogType;
}

const BlogCard = (props: Props) => {
  const { blog } = props;
  return (
    <div>
      <Card style={{ width: "300px", height: "230px" }}>
        {/* <CardImg
          top
        //   width="100%"
          src="/assets/318x180.svg"
          alt="Card image cap"
        /> */}
        <CardBody>
          <CardTitle tag="h5">{blog.title}</CardTitle>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              paddingRight: "5px",
              paddingLeft: 0,
              paddingBottom: "10px",
            }}
          >
            <CardSubtitle tag="h6" className="mb-2 text-muted">
              <div>Author</div>
              <div>
                {blog.publishedDate &&
                  moment(blog.publishedDate || "").fromNow()}
              </div>
            </CardSubtitle>
            <CardText>
              {blog.html.replace(/<[^>]*>?/gm, "").substr(0, 80)}...
            </CardText>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BlogCard;

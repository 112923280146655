import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import BlogCard from "./BlogCard";
import { BlogType } from "../types";
import moment from "moment";
import * as config from "../config.json";

const getBlogs = async () => {
  try {
    const response = await axios.get(`${config.apiDomain}/public/blogs`);
    return response?.data.items;
  } catch (error) {
    return [];
  }
};

export function ListBlogs() {
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const func = async () => {
      const allBlogs = (await getBlogs()) || {};
      setBlogs(allBlogs);
    };
    func();
  }, []);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {blogs &&
        blogs
          ?.sort((a: BlogType, b: BlogType) => {
            return (
              moment(b.publishedDate).unix() - moment(a?.publishedDate).unix()
            );
          })
          ?.map((blog: BlogType) => {
            console.log(blog.publishedDate);
            return (
              <div
                style={{
                  padding: "10px",
                }}
                onClick={() => {
                  history.push(`/blogs/${blog.blogId}`);
                }}
              >
                <BlogCard blog={blog} />
              </div>
            );
          })}
    </div>
  );
}

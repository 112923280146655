import React, { useState } from "react";
import { Input } from "reactstrap";

type Props = {
  text: string;
  onSave: (text: string) => any;
};

const EditableText = (props: Props) => {
  const { text, onSave } = props;

  const [isEnter, setIsEnter] = useState(false);
  const [newText, setNewText] = useState(text);

  return (
    <div
      onMouseEnter={() => setIsEnter(true)}
      onMouseLeave={() => {
        setIsEnter(false);
        if (newText !== text) {
          onSave(newText);
        }
      }}
    >
      {isEnter ? (
        <Input
          value={newText}
          type="textarea"
          onChange={(e: any) => {
            setNewText(e?.target?.value || "");
          }}
        />
      ) : (
        <div>{text}</div>
      )}
    </div>
  );
};

export default EditableText;

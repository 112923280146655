import React, { useState } from "react";
import { Button, Container, Input } from "reactstrap";
import * as config from "../config.json";
import { useEffect } from "react";
import { BlogType } from "../types";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Auth } from "aws-amplify";

interface Params {
  blogId: string;
}

export const EditBlog = () => {
  const history = useHistory();
  const blogId = useParams<Params>()?.blogId;
  const [error, setError] = useState<string | undefined>(undefined);
  const [blog, setBlog] = useState<BlogType | undefined>(undefined);
  const [title, setTitle] = useState("");
  const [blogBody, setBlogBody] = useState("");
  const [saving, setSaving] = useState(false);

  const callApi = async () => {
    if (blogId) {
      try {
        const res = await Auth.currentSession();
        const token = res.getIdToken().getJwtToken();

        const response = await axios.get(
          `${config.apiDomain}/private/blogs/${blogId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setBlog(response?.data);
        setError(undefined);
      } catch (error: any) {
        setError(error?.error);
      }
    }
  };

  const saveBlog = async () => {
    setSaving(true);

    const res = await Auth.currentSession();
    const token = res.getIdToken().getJwtToken();

    const savedBlogResponse = await axios.post(
      `${config.apiDomain}/private/blogs`,
      {
        blogId,
        title,
        html: blogBody,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBlog(savedBlogResponse?.data);
    setSaving(false);
    history.push(`/admin/blogs`);
  };

  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (blog) {
      setTitle(blog.title);
      setBlogBody(blog.html);
    }
  }, [blog]);

  return (
    <Container style={{ padding: "40px" }}>
      {error}

      <>
        <Input
          value={title}
          onChange={(e: any) => {
            setTitle(e?.target?.value || "");
          }}
          disabled={saving}
        />
        <Input
          value={blogBody}
          type="textarea"
          onChange={(e: any) => {
            setBlogBody(e?.target?.value || "");
          }}
          style={{
            width: "100%",
            height: "300px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
          disabled={saving}
        />
        <Button
          color="primary"
          onClick={() => {
            saveBlog();
          }}
        >
          Save
        </Button>
      </>
    </Container>
  );
};

export default EditBlog;

import React from "react";

import {
  AmplifySignOut,
  AmplifySignUp,
  AmplifyForgotPassword,
  AmplifyAuthenticator,
} from "@aws-amplify/ui-react";

const AuthView = () => {
  return (
    <div style={{ padding: "20px" }}>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignUp
          slot="sign-up"
          formFields={[
            {
              type: "username",
              label: "Email *",
              placeholder: "Enter your email",
              inputProps: { required: true, autocomplete: "username" },
            },
            {
              type: "password",
              label: "Password *",
              placeholder: "Password",
              inputProps: { required: true, autocomplete: "new-password" },
            },
          ]}
        />
        <AmplifyForgotPassword
          formFields={[
            {
              type: "username",
              label: "Email *",
              placeholder: "Enter your email",
            },
          ]}
        />
        <AmplifySignOut hidden={true} />
      </AmplifyAuthenticator>
    </div>
  );
};

export default AuthView;

import React, { useEffect, useState } from "react";
import { NavLink as RouterNavLink, useHistory } from "react-router-dom";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const styles = {
  navItem: {
    marginRight: "30px",
  },
};

interface Props {
  isAuthenticated?: boolean;
}

const NavBar = (props: Props) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const { isAuthenticated } = props;

  const [email, setEmail] = useState("");

  useEffect(() => {
    const getUserEmail = async () => {
      const userInfo = await Auth.currentUserInfo();
      setEmail(userInfo?.attributes?.email);
    };
    getUserEmail();
  }, [isAuthenticated]);

  const logoutWithRedirect = async () => {
    await Auth.signOut();
    history.push("/");
  };

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">
        <Container>
          {isAuthenticated && (
            <Nav className="mr-auto d-md-none" navbar>
              <NavItem style={styles.navItem}>
                <UncontrolledDropdown>
                  <DropdownToggle
                    nav
                    style={{ padding: 0, margin: 0, marginTop: "5px" }}
                  >
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      style={{ fontSize: "25px" }}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{email}</DropdownItem>
                    <DropdownItem id="qsLogoutBtn" onClick={logoutWithRedirect}>
                      Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
            </Nav>
          )}
          <NavbarBrand className="logo" />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar style={{ textAlign: "end" }}>
              <NavItem style={styles.navItem}>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={() => setIsOpen(false)}
                >
                  Home
                </NavLink>
              </NavItem>
              <NavItem style={styles.navItem}>
                <NavLink
                  tag={RouterNavLink}
                  to="/blogs"
                  exact
                  activeClassName="router-link-exact-active"
                  onClick={() => setIsOpen(false)}
                >
                  Blogs
                </NavLink>
              </NavItem>
              {isAuthenticated && (
                <NavItem style={styles.navItem}>
                  <NavLink
                    tag={RouterNavLink}
                    to="/admin/blogs"
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={() => setIsOpen(false)}
                  >
                    Admin Blogs
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem style={styles.navItem}>
                  <NavLink
                    tag={RouterNavLink}
                    to="/admin/todo"
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={() => setIsOpen(false)}
                  >
                    Todo
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem style={styles.navItem}>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => {
                      history.push("/auth");
                      setIsOpen(false);
                    }}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      style={{ fontSize: "25px" }}
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem header>{email}</DropdownItem>
                    <DropdownItem id="qsLogoutBtn" onClick={logoutWithRedirect}>
                      Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem style={styles.navItem}>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => {
                      history.push("/auth");
                      setIsOpen(false);
                    }}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;

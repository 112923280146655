import React, { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Home from "./views/Home";
import AdminBlogs from "./views/AdminBlogs";
import EditBlog from "./views/EditBlog";
import { createBrowserHistory } from "history";
import { ListBlogs } from "./Components/ListBlogs";
import { Blog } from "./Components/Blog";

import Amplify, { Auth, Hub } from "aws-amplify";
import AuthView from "./views/AuthView";
import { useCallback } from "react";
import config from "./config.json";
import TodoList from "./views/TodoList";
import AuthSignUp from "./views/AuthSignUp";

const history = createBrowserHistory();

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: config.auth.identityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: config.auth.region,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.auth.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.auth.userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //     sameSite: "strict" | "lax",
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //     domain: 'your_cognito_domain',
    //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //     redirectSignIn: 'http://localhost:3000/',
    //     redirectSignOut: 'http://localhost:3000/',
    //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // }
  },
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const updateUser = useCallback(async (data: any) => {
    console.log(data.payload.event);
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in");
        let res = await Auth.currentAuthenticatedUser();
        setIsAuthenticated(!!res);
        history.push("/admin/todo");
        break;
      case "signUp":
        console.log("user signed up");
        break;
      case "signOut":
        setIsAuthenticated(false);
        console.log("user signed out");
        break;
      case "signIn_failure":
        console.log("user sign in failed");
        break;
      case "tokenRefresh":
        console.log("token refresh succeeded");
        let currentUser = await Auth.currentAuthenticatedUser();
        setIsAuthenticated(!!currentUser);
        break;
      case "tokenRefresh_failure":
        setIsAuthenticated(false);
        console.log("token refresh failed");
        break;
      case "configured":
        console.log("the Auth module is configured");
    }
  }, []);

  useEffect(() => {
    Hub.listen("auth", updateUser);
    Auth.currentAuthenticatedUser().then((data: any) => {
      console.log(data);
      updateUser({ payload: { event: "tokenRefresh" } });
    });
  }, [updateUser]);

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar isAuthenticated={isAuthenticated} />

        <Container
          className="flex-grow-1 mt-5"
          style={{ paddingBottom: "50px" }}
        >
          <Switch>
            <Route exact path="/">
              <Home isAuthenticated={isAuthenticated} />
            </Route>
            <Route exact path="/blogs">
              <ListBlogs />
            </Route>
            <Route exact path="/blogs/:blogId">
              <Blog />
            </Route>
            <Route exact path="/auth">
              <AuthView />
            </Route>
            <Route exact path="/auth/signup">
              <AuthSignUp />
            </Route>
            {isAuthenticated && (
              <>
                <Route path="/admin/blogs" exact component={AdminBlogs} />
                <Route path="/admin/todo" exact component={TodoList} />
                <>
                  <Route path="/admin/blogs/edit" exact component={EditBlog} />
                  <Route
                    path="/admin/blogs/edit/:blogId"
                    component={EditBlog}
                  />
                </>
              </>
            )}
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

import React, { useState } from "react";
import { Container, Button, Table } from "reactstrap";
import * as config from "../config.json";
import { useEffect } from "react";
import { NavLink as RouterNavLink, useHistory } from "react-router-dom";
import { BlogType } from "../types";
import axios from "axios";
import moment from "moment";
import { Auth } from "aws-amplify";

export const AdminBlogs = () => {
  const history = useHistory();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: [],
    error: null,
  });
  const [, setSaving] = useState(false);

  const callApi = async () => {
    try {
      const res = await Auth.currentSession();
      const token = res.getIdToken().getJwtToken();

      const response = await axios.get(`${config.apiDomain}/private/blogs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response?.data;

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData?.items,
      });
    } catch (error: any) {
      setState({
        ...state,
        error: error?.error,
      });
    }
    setSaving(false);
  };

  const publishBlog = async (blogId: string) => {
    setSaving(true);

    const res = await Auth.currentSession();
    const token = res.getIdToken().getJwtToken();

    await axios.post(
      `${config.apiDomain}/private/blogs/${blogId}/publish`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    callApi();
  };

  const deleteBlog = async (blogId: string) => {
    setSaving(true);

    const res = await Auth.currentSession();
    const token = res.getIdToken().getJwtToken();

    await axios.delete(`${config.apiDomain}/private/blogs/${blogId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    callApi();
  };

  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Button
        style={{ float: "right", margin: "10px" }}
        color="primary"
        onClick={() => {
          history.push(`/admin/blogs/edit/`);
        }}
      >
        New Post
      </Button>
      <Table striped>
        <thead>
          <tr>
            <th>Title</th>
            <th>Published</th>
            <th style={{ textAlign: "right" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {state.apiMessage &&
            state.apiMessage
              ?.sort((a: BlogType, b: BlogType) => {
                return (
                  moment(b.publishedDate).unix() -
                  moment(a?.publishedDate).unix()
                );
              })
              ?.map((blog: BlogType) => {
                return (
                  <tr
                    key={blog?.blogId}
                    style={{
                      padding: "10px",
                      margin: 0,
                      border: "1px #f0f0f0 solid",
                    }}
                  >
                    <td>
                      <RouterNavLink
                        to={`/admin/blogs/edit/${blog?.blogId}`}
                        exact
                        activeClassName="router-link-exact-active"
                      >
                        {blog?.title}
                      </RouterNavLink>
                    </td>
                    <td style={{ fontSize: "small" }}>
                      {blog.publishedDate &&
                        moment(blog.publishedDate || "").fromNow()}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Button
                        size="sm"
                        color="info"
                        onClick={() => {
                          history.push(`/admin/blogs/edit/${blog?.blogId}`);
                        }}
                      >
                        Edit
                      </Button>{" "}
                      <Button
                        size="sm"
                        color="success"
                        disabled={!!blog.isPublished}
                        onClick={() => {
                          publishBlog(blog.blogId);
                        }}
                      >
                        Publish
                      </Button>{" "}
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => {
                          deleteBlog(blog.blogId);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </Container>
  );
};

export default AdminBlogs;
